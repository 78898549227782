<template>
  <div class="coupon-list-index">
    <div class="header-box">
      <div class="header-tabs">
        <div :class="['tab-item',tabType == 1 ? `${className}`: '']" @click="handleType(1)">可使用</div>
        <div :class="['tab-item',tabType == 2 ? `${className}`: '']" @click="handleType(2)">已使用</div>
        <div :class="['tab-item',tabType == 3 ? `${className}`: '']" @click="handleType(3)">已失效</div>
      </div>
      <div class="header-rule" @click="openRule()">
        <img src="../../assets/image/coupon/coupon-rule-icon.png" alt=""> 
        规则声明
      </div>
    </div>

    <!-- 列表组件 -->
    <div class="coupon-list" v-if="couponArr.length > 0">
      <coupon-list :dataList="couponArr"></coupon-list>
    </div>

    <!-- 空数据 -->
    <empty-list v-else></empty-list>

    <!-- 规则 -->
    <van-dialog 
      v-model:show="dialogShow" 
      title="规则声明" 
      :confirm-button-color	="confirmColor"
      :confirm-button-text="'知道了'">
      <div class="rule-text">
        {{ruleText}}
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import { reactive, toRefs, onMounted,getCurrentInstance } from 'vue'
  import { Dialog } from 'vant';
  export default {
    components: {
      [Dialog.Component.name]: Dialog.Component,
    },
    setup(){
      const state = reactive({
        className: 'anfang-act-tab',
        tabType: 1,
        dialogShow: false,
        confirmColor: '',
        couponArr: [],
        ruleText: '',
      })
      const { proxy: ctx } = getCurrentInstance();
      const { $tool: tool, $router: router, $store: store, $http: http } = ctx;

      const methods = {
        handleType(type){
          if(type == state.tabType) return;
          state.tabType = type;
          state.couponArr = [];
          ctx.getCoupon();
        },
        handleTheme(val){
          if(val == 'AnFang'){
            state.className = 'anfang-act-tab'
            state.confirmColor = '#377DFE'
          }else if(val == 'YuYin'){
            state.className = 'yuyin-act-tab'
            state.confirmColor = '#FE3A39'
          }else {
            state.className = 'zhongxun-act-tab'
            state.confirmColor = '#18B2A3'
          }
        },
        getText(){
          http.get('/card/getCouponRuleDesc')
          .then(({data:res})=>{
            if(res.code == 0){
              state.ruleText = res.data || '';
            }
          })
        },
        getCoupon(){
          const info = store.state.user.userInfo;
          http.post('/card/getCouponListByIccid',{
            iccid: info.iccid,
            couponStatus: state.tabType,
          }).then(({data:res})=>{
            if(res.code == 0){
              state.couponArr = res.data || [];
              state.couponArr.forEach(v=>{
                v.showExplain = false;
              })
              return;
            }
            state.couponArr = [];
          })
        },
        openRule(){
          state.dialogShow = true;
        },

      }

      onMounted(()=>{
        //获取主题名称
        methods.handleTheme(store.state.common.theme)
        ctx.getCoupon();
        ctx.getText()
      })

      return {
        ...toRefs(state),
        ...methods,
      }
    }
  }
</script>

<style lang="scss" scoped>
  .coupon-list-index {
    background: #F1F5FB;
    min-height: 100vh;
  }
  .header-box {
    width: 100%;
    padding: 40px 32px 20px;
    box-sizing: border-box;
    background: #F1F5FB;
    position: fixed;
    z-index: 11;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-tabs {
      display: flex;
      align-items: center;
      .tab-item {
        width: 104px;
        height: 48px;
        border-radius: 8px;
        text-align: center;
        line-height: 46px;
        border: 2px solid rgba(0,0,0,0.1);
        box-sizing: border-box;
        color: rgba(0,0,0,0.6);
        font-size: 24px;
        margin-right: 16px;
      }
      .anfang-act-tab {
        background: #377DFE;
        border: 0px;
        color: #fff;
      }
      .yuyin-act-tab {
        background: rgba(0,0,0,0.8);
        border: 0px;
        color: #fff;
      }
      .zhongxun-act-tab {
        background: #18B2A3;
        border: 0px;
        color: #fff;
      }
    }
    .header-rule {
      display: flex;
      align-items: center;
      font-size: 28px;
      color: #000;
      img {
        width: 40px;
        height: 40px;
        margin-right: 8px;
      }
    }
  }
  .rule-text {
    padding: 24px 40px 32px;
    color: rgba(0,0,0,0.6);
    font-size: 28px;
    line-height: 40px;
  }

  .coupon-list {
    padding: 128px 32px 0px;
    box-sizing: border-box;
  }

  
</style>